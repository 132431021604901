import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import { Avatar } from "@/components/atoms";
import useUserData from "@/hooks/useUserData";
import { ZIM, ZIMConversation } from "zego-zim-web";
import { generateToken } from "../../pages/messages/generate-token";
import { getMyPeopleCount } from "@/queries/people/getFollowersAndFriendsCount.query";

import { MenuItem, MenuList, styled } from "@mui/material";
import { useMutation } from "wagmi";
import { ErrorDto } from "@/types/error.dto";

interface MenuItemData {
  path: string;
  icon: JSX.Element;
  label: string;
}

export const menuItems: MenuItemData[] = [
  { path: "/feed", label: "Feed", icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-smart-home" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /> <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105" /></svg>},
  { path: "/candao-news", label: "Candao News", icon: <svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-news"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" /><path d="M8 8l4 0" /><path d="M8 12l4 0" /><path d="M8 16l4 0" /></svg>},
  { path: "/dashboard", label: "Dashboard", icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>},
  { path: "/social-graph", label: "Social Graph", icon: <svg  xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users-group" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" /><path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M17 10h2a2 2 0 0 1 2 2v1" /><path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /> <path d="M3 13v-1a2 2 0 0 1 2 -2h2" /></svg>},
  { path: "/staking-hubs", label: "Staking Hubs", icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 9.33398C6 10.4387 7.79067 11.334 10 11.334C12.2093 11.334 14 10.4387 14 9.33398C14 8.22932 12.2093 7.33398 10 7.33398C7.79067 7.33398 6 8.22932 6 9.33398Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M6 9.33398V12.0007C6 13.1047 7.79067 14.0007 10 14.0007C12.2093 14.0007 14 13.1047 14 12.0007V9.33398" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M2 4C2 4.71467 2.76267 5.37467 4 5.732C5.23733 6.08933 6.76267 6.08933 8 5.732C9.23733 5.37467 10 4.71467 10 4C10 3.28533 9.23733 2.62533 8 2.268C6.76267 1.91067 5.23733 1.91067 4 2.268C2.76267 2.62533 2 3.28533 2 4Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M2 4V10.6667C2 11.2587 2.51467 11.6333 3.33333 12" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M2 7.33398C2 7.92598 2.51467 8.30065 3.33333 8.66732" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/></svg>},
  { path: "/messages", label: "Messages", icon: <svg  width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.33333 3.99996H9.66667M4.33333 6.66663H8.33333M5 9.99996H3C2.46957 9.99996 1.96086 9.78925 1.58579 9.41417C1.21071 9.0391 1 8.53039 1 7.99996V2.66663C1 2.13619 1.21071 1.62749 1.58579 1.25241C1.96086 0.87734 2.46957 0.666626 3 0.666626H11C11.5304 0.666626 12.0391 0.87734 12.4142 1.25241C12.7893 1.62749 13 2.13619 13 2.66663V7.99996C13 8.53039 12.7893 9.0391 12.4142 9.41417C12.0391 9.78925 11.5304 9.99996 11 9.99996H9L7 12L5 9.99996Z" strokeWidth="1.5"  strokeLinecap="round" strokeLinejoin="round"/></svg>},
  { path: "/swap", label: "Swap", icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2.66667C2 2.48986 2.07024 2.32029 2.19526 2.19526C2.32029 2.07024 2.48986 2 2.66667 2H5.33333C5.51014 2 5.67971 2.07024 5.80474 2.19526C5.92976 2.32029 6 2.48986 6 2.66667V5.33333C6 5.51014 5.92976 5.67971 5.80474 5.80474C5.67971 5.92976 5.51014 6 5.33333 6H2.66667C2.48986 6 2.32029 5.92976 2.19526 5.80474C2.07024 5.67971 2 5.51014 2 5.33333V2.66667Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M10 10.6667C10 10.4899 10.0702 10.3203 10.1953 10.1953C10.3203 10.0702 10.4899 10 10.6667 10H13.3333C13.5101 10 13.6797 10.0702 13.8047 10.1953C13.9298 10.3203 14 10.4899 14 10.6667V13.3333C14 13.5101 13.9298 13.6797 13.8047 13.8047C13.6797 13.9298 13.5101 14 13.3333 14H10.6667C10.4899 14 10.3203 13.9298 10.1953 13.8047C10.0702 13.6797 10 13.5101 10 13.3333V10.6667Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M14 7.33333V5.33333C14 4.97971 13.8595 4.64057 13.6095 4.39052C13.3594 4.14048 13.0203 4 12.6667 4H8.66667M8.66667 4L10.6667 6M8.66667 4L10.6667 2" strokeWidth="1.25"strokeLinecap="round" strokeLinejoin="round"/><path d="M2 8.66663V10.6666C2 11.0202 2.14048 11.3594 2.39052 11.6094C2.64057 11.8595 2.97971 12 3.33333 12H7.33333M7.33333 12L5.33333 9.99996M7.33333 12L5.33333 14" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" /></svg>},
  { path: "/cdo", label: "Claim CDO", icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.33325 8.00016C1.33325 8.87564 1.50569 9.74255 1.84072 10.5514C2.17575 11.3602 2.66682 12.0952 3.28587 12.7142C3.90493 13.3333 4.63986 13.8243 5.4487 14.1594C6.25753 14.4944 7.12444 14.6668 7.99992 14.6668C8.8754 14.6668 9.7423 14.4944 10.5511 14.1594C11.36 13.8243 12.0949 13.3333 12.714 12.7142C13.333 12.0952 13.8241 11.3602 14.1591 10.5514C14.4941 9.74255 14.6666 8.87564 14.6666 8.00016C14.6666 6.23205 13.9642 4.53636 12.714 3.28612C11.4637 2.03588 9.76803 1.3335 7.99992 1.3335C6.23181 1.3335 4.53612 2.03588 3.28587 3.28612C2.03563 4.53636 1.33325 6.23205 1.33325 8.00016Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><foreignObject x="4" y="4" width="9.33337" height="9.33337"><svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.7744 4.00317L7.32661 2.47574L6.47562 1.64199C5.14807 0.341341 2.98995 0.341341 1.66241 1.64199C0.334861 2.94264 0.334861 5.05703 1.66241 6.35768C2.98995 7.65833 5.14807 7.65833 6.47562 6.35768L7.33341 5.5306L7.17683 5.37719L6.12841 4.35001L5.7744 4.00317ZM5.7744 4.00317L4.92341 3.16942C4.45366 2.70919 3.69117 2.70919 3.22142 3.16942C2.75168 3.62965 2.75168 4.37669 3.22142 4.83692C3.69117 5.29715 4.45366 5.29715 4.92341 4.83692L5.7744 4.00317Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/></svg></foreignObject></svg> },
];

const UpperLeftNavigation = () => {
  const router = useRouter();
  const getUser = useUserData();
  const [zim, setZim] = useState<ZIM | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<number>(() => {
    const storedCount = localStorage.getItem("unreadMessages");
    return storedCount ? parseInt(storedCount, 10) : 0;
  });
  const [onChainFollowersCount, setOnChainFollowersCount] = useState<number | undefined>(undefined);
  const [followingCount, setFollowingCount] = useState<number | undefined>(undefined);
  
  const getPeopleCount = useMutation(getMyPeopleCount, {
    onSuccess: (data) => {
      // console.log(data)
      setOnChainFollowersCount(data.follow.followers);
      setFollowingCount(data.follow.following);
    },
    onError: (e: ErrorDto) => {
      console.error(e);
    },
  });

  useEffect(() => {
    (async () => {
      const { ZIM } = await import("zego-zim-web");
      var zim = ZIM.getInstance();
      zim.setLogConfig({logLevel: 'disable'})
      setZim(zim);
    })();
    function handleResize() {
      const isMobile = window.innerWidth <= 1024;
      setIsMobile(isMobile);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if(getUser && getUser.profile.socialAccountsData && getUser.profile.socialAccountsData.filter((account: { isVerified: boolean; }) => account.isVerified).length >= 3){
      setIsVerified(true);
    }
  }, [])

  useEffect(() => {
    getPeopleCount.mutate();
  },[])

  zim && zim.on('conversationTotalUnreadMessageCountUpdated', function(zim, { totalUnreadMessageCount }){
    localStorage.setItem("unreadMessages", totalUnreadMessageCount.toString());
    setUnreadMessages(totalUnreadMessageCount);
  })

  const handleMenuItemClick = (path: string) => {
    router.push(path).then(() => {});
  };

  const formatNumber = (num: number, precision = 2) => {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'B', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 },
        { suffix: '', threshold: 1 },
    ];
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        let formatted = (num / found.threshold).toFixed(precision);
        if (formatted.indexOf('.') !== -1) {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + found.suffix;
    } else {
        return num.toString();
    }
  };

  if(isMobile) {return <></>};

  return (

    <div className="row mb-4 flex justify-between overflow-hidden rounded-2xl border bg-white pb-[16px] pl-0 pr-0 pt-[16px] mx-auto">
      <div className="row m-0 mb-2 flex">
        <a href={`/@${getUser?.username}`}>
          <Avatar
            size="medium"
            color="indigo"
            rounded
            className="mb-1 mr-1 shrink-0 p-0"
            avatarUrl={getUser?.profile?.avatarUrl}
            username={getUser?.username}
          />
        </a>
        <div className="flex flex-row gap-1">
          <span onClick={() => router.push('/my-profile')} className="cursor-pointer break-words text-[14px] font-medium text-gray-900">
            {getUser?.profile.firstName} {getUser?.profile.lastName}
          </span>
            {
              isVerified &&
              <div className="flex items-end mb-1">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="#5850EC"  className="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
              </div>
            }
        </div>
        <span onClick={() => router.push('/my-profile')} className="cursor-pointer break-words text-[12px] text-gray-600">
          @{getUser?.username}
        </span>
        { onChainFollowersCount !== undefined && followingCount !== undefined && 
          <div className="flex flex-row pt-3 gap-1">
            <span className="text-gray-600 text-[12px] break-words">
              <span className="text-gray-900 font-medium">{formatNumber(followingCount)} </span>
              Following · 
            </span>
            <span className="text-gray-600 text-[12px] break-words">
              <span className="text-gray-900 font-medium">{formatNumber(onChainFollowersCount)} </span>
              Followers
            </span>
          </div>
        }
      </div>
      <MenuList style={{ padding: 0 }}>
        {menuItems.map((item) => (
          <StyledMenuItem
            key={item.path}
            isSelected={router.pathname === item.path}
            onClick={() => handleMenuItemClick(item.path)}
          >
            <div
              className={`${router.pathname === item.path ? "mr-[12px] h-[20px] w-[4px] rounded-br-lg rounded-tr-lg bg-indigo-600" : "mr-[15px]"}`}
            />
            <div
              className={`column mr-4 flex h-full w-full rounded-xl p-3 ${router.pathname === item.path && "bg-gray-50"}`}
            >
              <div className="mt-[2px]">
                {React.cloneElement(item.icon, {
                  stroke: router.pathname === item.path ? "#5850EC" : "#6B7280",
                })}
              </div>
              <span
                className={`ml-2 text-[14px] ${router.pathname === item.path ? "font-medium text-gray-900" : "text-gray-700"}`}
              >
                {
                  item.label === "Messages" && unreadMessages > 0 ?
                  <div className="flex flex-row items-center gap-2 mt-[-2px]">
                    {item.label}
                    <div className="flex flex-row rounded-full min-w-[16px] h-[16px] justify-center bg-indigo-100 text-[11px] text-indigo-600">
                      {unreadMessages}
                    </div>
                  </div>
                   : <>{item.label}</>
                }
              </span>
            </div>
          </StyledMenuItem>
        ))}
      </MenuList>
    </div>
  );
};

const StyledMenuItem = styled(MenuItem)<{ isSelected: any }>(
  ({ isSelected }: { isSelected: boolean }) => ({
    padding: "0px",
    margin: "0px",
    "&:hover": { backgroundColor: "transparent" },
  }),
);

export default UpperLeftNavigation;